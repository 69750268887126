import React, {Component} from 'react';

class Presale extends Component{

    render() {

        return(
            <React.Fragment>

                <div className="main-form-container__points">

                    <div className="container">

                        <div className="main-form-container__points__header">
                            {(this.props.version === "1" || this.props.version === "nb") &&
                                <div>
                                    <h1>How much tax-free cash can you release?</h1>
                                    {/*<p>Get a personalised calculation with no-obligation from a friendly expert advisor</p>*/}
                                    <p>Get a personalised calculation with no-obligation!</p>
                                </div>

                            }
                            {(this.props.version === "2" || this.props.version === "3") &&
                                <div>
                                    <h1>Calculate How Much You Could Release Today</h1>
                                    <p>Get a personalised calculation with no-obligation!</p>
                                </div>
                            }


                        </div>

                        {(this.props.version === "1" || this.props.version === "nb") &&
                            <div className="main-form-container__points__body">
                                <h2>What could equity release do for you?</h2>
                                <ul>
                                    <li><i className="fas fa-check-circle"></i> Tax-free funds from your property</li>
                                    <li><i className="fas fa-check-circle"></i> Gift an early inheritance</li>
                                    <li><i className="fas fa-check-circle"></i> Pay off existing mortgage or credit cards</li>
                                </ul>
                            </div>
                        }




                    </div>

                </div>



            </React.Fragment>
        )
    }

}

export default Presale;
