import React, {Component} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import logos from "../img/er-logos.png";

class Sales1 extends Component{


    componentDidMount () {
        //let sa_interval = 5000;
        function saLoadScript(src) {
            var js = window.document.createElement('script');
            js.src = src; js.type = 'text/javascript';
            document.getElementsByTagName("head")[0].appendChild(js);
        }
        if (typeof(shopper_first) == 'undefined') saLoadScript('//www.shopperapproved.com/widgets/testimonial/3.0/21691.js');
        //shopper_first = true;
    }

    render() {

        return(
            <React.Fragment>
                {this.props.brandLogos === "yes" &&
                    <section className="provider-logos">
                        <div className="container">
                            <p>Compare deals from the leading providers</p>
                            <img src={logos} alt="Compare deals from the leading providers"/>
                        </div>

                    </section>
                }

                <section className="council-section">
                    <div className="container">
                        <h2>Equity Release Council Guarantee</h2>

                        <p>Our expert partners only work with providers registered with the Equity Release Council, the equity release industry body.  </p>

                        <div className="row">
                            <div className="col-lg-4">
                                <div className="council-section__box">
                                    <div className="council-section__box__check">
                                        <i className="fas fa-check"></i>
                                    </div>
                                    <p>No monthly repayments</p>
                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className="council-section__box">
                                    <div className="council-section__box__check">
                                        <i className="fas fa-check"></i>
                                    </div>
                                    <p>Retain 100% Home Ownership</p>
                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className="council-section__box">
                                    <div className="council-section__box__check">
                                        <i className="fas fa-check"></i>
                                    </div>
                                    <p>No negative equity guaranteed</p>
                                </div>

                            </div>
                        </div>



                    </div>
                </section>


                <section className="triad-section">
                    <div className="container">

                        <div className="row">
                            <div className="col-12">
                                <h2>Get your deals in just 3 easy steps...</h2>
                            </div>



                        <ul className="d-flex flex-wrap">
                            <li className="col-md-4 col-12">
                                <div className="triad-section__box">

                                    <span className="triad-section__box__number">1</span>

                                    <h4>Fill Out Form</h4>
                                    <p>Complete our short form which takes less than 30 seconds and we will do the rest!</p>
                                </div>

                            </li>
                            <li className="col-md-4 col-12">

                                <div className="triad-section__box">

                                    <span className="triad-section__box__number">2</span>

                                    <h4>Verify Eligibility</h4>
                                    <p>We match your requirements to a suitable expert advisor who will be able to help you
                                        with your enquiry.</p>
                                </div>


                            </li>
                            <li className="col-md-4 col-12">

                                <div className="triad-section__box">
                                    <span className="triad-section__box__number">3</span>
                                    <h4>Get Release Deals</h4>
                                    <p>Find out how much you could release! Our friendly advisors will be in touch to
                                        provide you with a range of quotes and discuss possible next steps. This initial
                                        consultation is free with absolutely no obligation.</p>
                                </div>


                            </li>
                        </ul>

                        </div>

                    </div>
                </section>


                <section className="service-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <h2>Fequently Asked Questions</h2>
                            </div>

                            <div className="col-lg-8">



                                <Accordion>
                                    <div className="card">
                                        <div className="card-header">
                                            <Accordion.Toggle variant="link" as="button" className="btn btn-link" eventKey="0">
                                                What Is Equity Release?
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="0">
                                            <div className="card-body">

                                                <p>Equity release can help you unlock the value of your property and
                                                    turn it into a cash lump sum or in smaller amounts. You can use
                                                    the money released from your home however you wish; this could
                                                    include gifting to loved ones, taking a holiday, doing some home
                                                    improvements or clearing existing debt.</p>
                                                <p>
                                                    To access Equity release you need to take expert advice which is
                                                    where we can help. We help you access expert FCA authorised
                                                    advisors that are members of the Equity Release Council.
                                                </p>
                                                <p className="text-muted">
                                                    Please note, releasing equity will reduce any inheritance for
                                                    your loved ones and may impact any means-tested state
                                                    benefits.</p>

                                            </div>
                                        </Accordion.Collapse>

                                        <div className="card-header">
                                            <Accordion.Toggle variant="link" as="button" className="btn btn-link" eventKey="1">
                                                Am I eligible?
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="1">
                                            <div className="card-body">
                                                <p>Equity release is available to homeowners aged 55-95 and will generally require a home value of at least £70,000.</p>
                                            </div>
                                        </Accordion.Collapse>


                                        <div className="card-header">
                                            <Accordion.Toggle variant="link" as="button" className="btn btn-link" eventKey="2">
                                                Equity Release myths?
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="2">
                                            <div className="card-body">

                                                <p>“I won’t be able to move house” – any Equity release plans
                                                    approved by the Equity Release Council guarantee that you are
                                                    able to move your plan to a new home; the new home will have to
                                                    be acceptable to your lender and should be discussed before
                                                    going ahead.</p>
                                                <p>
                                                    “I can’t leave an inheritance” - it is possible to ring-fence a
                                                    portion of the property value with a protected lifetime
                                                    mortgage; this protects a percentage of your home’s future value
                                                    regardless of what is outstanding on the loan. This can be
                                                    discussed with your advisor.</p>

                                                <p>“The estate could end up owing more than the homes value” – the
                                                    “No Negative Equity Guarantee” ensures that your estate will
                                                    never owe more than the value of your property when it is
                                                    sold.</p>

                                            </div>
                                        </Accordion.Collapse>


                                        <div className="card-header">
                                            <Accordion.Toggle variant="link" as="button" className="btn btn-link" eventKey="3">
                                                Why Us?
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="3">
                                            <div className="card-body">

                                                <p>We connect you to expert FCA authorised advisors that can search the whole market to help secure the best plan for your needs and circumstances. All quotes and initial advice are provided free of charge and without obligation. Should you wish to proceed and your plan is successfully taken out there would be an agreed fee with the advisor typically around 2% of the amount released.</p>

                                            </div>
                                        </Accordion.Collapse>
                                    </div>
                                </Accordion>

                            </div>

                        </div>

                    </div>

                </section>

                <section className="sa_reviews">

                    <div className="container">


                        <div className="shopperapproved_widget sa_rotate sa_count1 sa_vertical sa_count1 sa_bgInherit sa_colorInherit sa_borderGray sa_noborder sa_jMy sa_flex sa_hidelinks sa_large sa_showdate ">
                        </div>

                    </div>

                </section>





            </React.Fragment>
        )
    }

}

export default Sales1;
