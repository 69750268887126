import React, {Component} from 'react';
import {Link} from "react-router-dom";

class PageNotFound extends Component{

    render() {

        return(
            <React.Fragment>
                <section className="service-section">
                    <div className="container">
                        <h1>Oops, this page could not be found!</h1>
                        <p><Link to="/">Take me home</Link></p>
                    </div>

                </section>
            </React.Fragment>
        )
    }

}

export default PageNotFound;
