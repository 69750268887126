import React, {Component} from 'react';

class Footer extends Component{



    render() {

        return(
            <React.Fragment>

                <footer className="main-footer">

                    <div className="container">

                            <p>Equity release reduces your estate’s value and may affect any means-tested benefits
                                you’re eligible for. A lifetime mortgage, which is a loan secured against your home, is
                                the most popular form of equity release and you will still own your home. Think
                                carefully before releasing equity and make sure it’s right for you.</p>

                                      <p>  The details of financial services and products published on this site are for
                                        information purposes only and do not constitute financial advice.
                                      </p>
                                          <p>  By submitting the enquiry form you agree that the information provided is
                                            true and accurate and that ableplan.uk may send the details of this enquiry
                                            to an appropriate broker for the purpose of furthering your enquiry and that
                                            the broker may contact you for further information as required. We will not
                                            send, sell, loan or lease your data to any other thrid party except those
                                            needed to provide the service you have requested.
                            </p>

                        <p>Ableplan.uk is a trading style of 32 Squared Media Ltd, Company Number 09095076. Registered address: 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ. Enquiries: contact@32squared.co.uk</p>

                        <div className="menu-links">

                            <p className="menu-links__copyright">Copyright © 2022 Able Plan All Rights Reserved.</p>



                            <ul className="menu-links__legal">
                                <li><button className="btn btn-link" onClick={() => this.props.openPopup('terms')}>Terms</button></li>
                                <li><button className="btn btn-link" onClick={() => this.props.openPopup('privacy')}>Privacy</button></li>
                                <li><button className="btn btn-link" onClick={() => this.props.openPopup('cookie')}>Cookie policy</button></li>
                            </ul>



                        </div>





                    </div>

                </footer>


                <div className={this.props.open.main ? "pop-up-outer pop-up-outer--open" : "pop-up-outer"}>

                    <div className="pop-up" >
                        <div className="pop-up__close" onClick={this.props.closePopup}>
                            <i className="fas fa-times"></i>
                        </div>


                        <div className={this.props.open.terms ? "" : "d-none"}>

                            <div>
                                <h1>Terms &amp; conditions</h1>
                                <p>These Terms and Conditions, together with any and all other documents referred to<br />
                                    herein, set out the terms of use under which you may use this website,<br />
                                    http://www.ableplan.uk (“Our Site”), which is a trading style of 32 Squared Media Ltd,
                                    Company Number 09095076. Registered Address: 71-75 Shelton Street, Covent Garden,
                                    London, WC2H 9JQ. Please read these Terms and Conditions<br />
                                    carefully and ensure that you understand them. Your agreement to comply with and
                                    be<br />
                                    bound by these Terms and Conditions is deemed to occur upon your first use of
                                    Our<br />
                                    Site. If you do not agree to comply with and be bound by these Terms and Conditions,<br />
                                    you must stop using Our Site immediately.</p>
                                <h4>DEFINITIONS AND INTERPRETATION</h4>
                                <p>1.1 In these Terms and Conditions, unless the context otherwise requires, the<br />
                                    following expressions have the following meanings:</p>
                                <p><strong>“We/Us/Our/the Company”</strong><br />
                                    means an account required to access and/or use certain areas and features of Our<br />
                                    Site;</p>
                                <p><strong>“Content”</strong><br />
                                    means any and all text, images, audio, video, scripts, code, software, databases and<br />
                                    any other form of information capable of being stored on a computer that appears on,<br />
                                    or forms part of Our Site.</p>
                                <p><strong>“Cookie”</strong><br />
                                    means a small file placed on your computer or device by Our Site when you visit<br />
                                    certain parts of Our Site and/or when you use certain features of Our Site.</p>
                                <p><strong>“Cookie Law”</strong><br />
                                    means the relevant parts of the Privacy and Electronic Communications (EC Directive)<br />
                                    Regulations 2003 and of EU Regulation 2016/679 General Data Protection
                                    Regulation<br />
                                    (“GDPR”).</p>
                                <p><strong>“Personal Data”</strong><br />
                                    means any and all data that relates to an identifiable person who can be directly or<br />
                                    indirectly identified from that data, as defined EU Regulation 2016/679 General Data<br />
                                    Protection Regulation (“GDPR”).</p>
                                <h4>2. INFORMATION ABOUT US</h4>
                                <p>2.1 (“Our Site”), is owned and operated by the Company.</p>
                                <h4>3. ACCESS TO OUR SITE</h4>
                                <p>3.1 Access to Our Site is free of charge.<br />
                                    3.2 It is your responsibility to make any and all arrangements necessary in order to<br />
                                    access Our Site.<br />
                                    3.3 Access to Our Site is provided “as is” and on an “as available” basis. We
                                    may<br />
                                    alter, suspend or discontinue Our Site (or any part of it) at any time and
                                    without<br />
                                    notice. We will not be liable to you in any way if Our Site (or any part of it)
                                    is<br />
                                    unavailable at any time and for any period.</p>
                                <h4>4. INTELLECTUAL PROPERTY RIGHTS</h4>
                                <p>4.1 All Content included on Our Site and the copyright and other intellectual<br />
                                    property rights subsisting in that Content, unless specifically labelled
                                    otherwise,<br />
                                    belongs to or has been licensed by us.<br />
                                    4.2 Subject to sub-Clauses 4.3 you may not reproduce, copy, distribute, sell,<br />
                                    rent, sub-licence, store, or in any other manner re-use Content from our Site<br />
                                    unless given express written permission to do so by us.<br />
                                    4.3 You may:<br />
                                    4.3.1 Access, view and use our site in a web browser (including any web browsing<br />
                                    capability built into other types of software or app);<br />
                                    4.3.2 Download Our Site (or any part of it) for caching;<br />
                                    4.3.3 Print pages from our site;<br />
                                    4.3.4 Download extracts from pages on our site; and<br />
                                    4.3.5 Save pages from Our Site for later and/or offline viewing.<br />
                                    4.4 Our status as the owner and author of the Content on Our Site (or that of<br />
                                    identified licensors, as appropriate) must always be acknowledged.<br />
                                    4.5 You may not use any Content saved or downloaded from Our Site for commercial<br />
                                    purposes without first obtaining a licence from Us (or our licensors, as<br />
                                    appropriate) to do so. This does not prohibit the normal access, viewing and use<br />
                                    of Our Site for general information purposes whether by business users or<br />
                                    consumers.</p>
                                <h4>5. LINKS TO OUR SITE</h4>
                                <p>5.1 You may link to Our Site provided that:<br />
                                    5.1.1 You do so in a fair and legal manner;<br />
                                    5.1.2 You do not do so in a manner that suggests any form of association,<br />
                                    endorsement or approval on Our part where none exists;<br />
                                    5.1.3 You do not use any logos or trademarks displayed on our site without Our<br />
                                    express written permission; and<br />
                                    5.1.4 You do not do so in a way that is calculated to damage our reputation or
                                    to<br />
                                    take unfair advantage of it.<br />
                                    5.2 You may not link to Our Site from any other site the main content of which<br />
                                    contains material that:<br />
                                    5.2.1 is sexually explicit;<br />
                                    5.2.2 is obscene, deliberately offensive, hateful or otherwise inflammatory;<br />
                                    5.2.3 promotes violence;<br />
                                    5.2.4 promotes or assists in any form of unlawful activity;<br />
                                    5.2.5 discriminates against, or is in any way defamatory of, any person, group
                                    or<br />
                                    class of persons, race, sex, religion, nationality, disability, sexual
                                    orientation,<br />
                                    or age;<br />
                                    5.2.6 is intended or is otherwise likely to threaten, harass, annoy, alarm,<br />
                                    inconvenience, upset, or embarrass another person;<br />
                                    5.2.7 is calculated or is otherwise likely to deceive another person;<br />
                                    5.2.8 is intended or is otherwise likely to infringe (or to threaten to
                                    infringe)<br />
                                    another person’s privacy;<br />
                                    5.2.9 misleadingly impersonates any person or otherwise misrepresents the
                                    identity<br />
                                    or affiliation of a particular person in a way that is calculated to deceive<br />
                                    (obvious parodies are not included in this definition provided that they do not fall<br />
                                    within any of the other provisions of this sub-Clause 5.4);<br />
                                    5.2.10 implies any form of affiliation with Us where none exists;<br />
                                    5.2.11 infringes, or assists in the infringement of, the intellectual property<br />
                                    rights (including, but not limited to, copyright, trademarks and database rights) of<br />
                                    any other party; or<br />
                                    5.2.12 is made in breach of any legal duty owed to a third party including, but
                                    not<br />
                                    limited to, contractual duties and duties of confidence.<br />
                                    5.3 The content restrictions in sub-Clause 5.2 do not apply to content submitted
                                    to<br />
                                    sites by other users provided that the primary purpose of the site accords with
                                    the<br />
                                    provisions of sub-Clause 5.2. You are not, for example, prohibited from posting<br />
                                    links on general-purpose social networking sites merely because another user may<br />
                                    post such content. You are, however, prohibited from posting links on websites which<br />
                                    focus on or encourage the submission of such content from users.</p>
                                <h4>6. LINKS TO OTHER SITES</h4>
                                <p>Links to other sites may be included on Our Site. Unless expressly stated, these<br />
                                    sites are not under Our control. We neither assume nor accept responsibility or<br />
                                    liability for the content of third party sites. The inclusion of a link to
                                    another<br />
                                    site on Our Site is for information only and does not imply any endorsement of
                                    the<br />
                                    sites themselves or of those in control of them.</p>
                                <h4>7. DISCLAIMERS</h4>
                                <p>7.1 Nothing on Our Site constitutes advice on which you should rely. It is
                                    provided<br />
                                        for general information purposes only.<br />
                                        7.2 Insofar as is permitted by law, We make no representation, warranty, or<br />
                                        guarantee that Our Site will meet your requirements, that it will not
                                        infringe<br />
                                        the rights of third parties, that it will be compatible with all software
                                        and<br />
                                        hardware, or that it will be secure.<br />
                                        7.3 We make reasonable efforts to ensure that the Content on Our Site is<br />
                                        complete, accurate, and up-to-date. We do not, however, make any<br />
                                        representations, warranties or guarantees (whether express or implied) that
                                        the<br />
                                        Content is complete, accurate, or up-to-date.</p>
                                <h4>8. OUR LIABILITY</h4>
                                <p>8.1 To the fullest extent permissible by law, We accept no liability to any user
                                    for<br />
                                        any loss or damage, whether foreseeable or otherwise, in contract, tort
                                        (including<br />
                                        negligence), for breach of statutory duty, or otherwise, arising out of or
                                        in<br />
                                        connection with the use of (or inability to use) Our Site or the use of or
                                        reliance<br />
                                        upon any Content included on Our Site.<br />
                                        8.2 To the fullest extent permissible by law, We exclude all
                                        representations,<br />
                                        warranties, and guarantees (whether express or implied) that may apply to
                                        Our<br />
                                        Site or any Content included on Our Site.<br />
                                        8.3 If you are a business user, We accept no liability for loss of profits,<br />
                                        sales, business or revenue; loss of business opportunity, goodwill or<br />
                                        reputation; loss of anticipated savings; business interruption; or for any<br />
                                        indirect or consequential loss or damage.<br />
                                        8.4 We exercise all reasonable skill and care to ensure that Our Site is
                                        free<br />
                                        from viruses and other malware. We accept no liability for any loss or
                                        damage<br />
                                        resulting from a virus or other malware, a distributed denial of service attack,<br />
                                        or other harmful material or event that may adversely affect your hardware,<br />
                                        software, data or other material that occurs as a result of your use of Our Site<br />
                                        (including the downloading of any Content from it) or any other site referred to<br />
                                        on Our Site.<br />
                                        8.5 We neither assume nor accept responsibility or liability arising out of
                                        any<br />
                                        disruption or non-availability of Our Site resulting from external causes<br />
                                        including, but not limited to, ISP equipment failure, host equipment
                                        failure,<br />
                                        communications network failure, natural events, acts of war, or legal<br />
                                        restrictions and censorship.<br />
                                        8.6 Nothing in these Terms and Conditions excludes or restricts Our
                                        liability<br />
                                        for fraud or fraudulent misrepresentation, for death or personal injury<br />
                                        resulting from negligence, or for any other forms of liability which cannot
                                        be<br />
                                        excluded or restricted by law. For full details of consumers’ legal rights,<br />
                                        including those relating to digital content, please contact your local Citizens’<br />
                                        Advice Bureau or Trading Standards Office.</p>
                                <h4>9. VIRUSES, MALWARE AND SECURITY</h4>
                                <p>9.1 We exercise all reasonable skill and care to ensure that Our Site is secure
                                    and<br />
                                        free from viruses and other malware.<br />
                                        9.2 You are responsible for protecting your hardware, software, data and
                                        other<br />
                                        material from viruses, malware, and other internet security risks.<br />
                                        9.3 You must not deliberately introduce viruses or other malware, or any
                                        other<br />
                                        material which is malicious or technologically harmful either to or via Our<br />
                                        Site.<br />
                                        9.4 You must not attempt to gain unauthorised access to any part of Our
                                        Site,<br />
                                        the server on which Our Site is stored, or any other server, computer, or<br />
                                        database connected to Our Site.<br />
                                        9.5 You must not attack Our Site by means of a denial of service attack, a<br />
                                        distributed denial of service attack, or by any other means.<br />
                                        9.6 By breaching the provisions of sub-Clauses 9.3 to 9.5 you may be
                                        committing<br />
                                        a criminal offence under the Computer Misuse Act 1990. Any and all such breaches<br />
                                        will be reported to the relevant law enforcement authorities and We will<br />
                                        cooperate fully with those authorities by disclosing your identity to them. Your<br />
                                        right to use Our Site will cease immediately in the event of such a breach.</p>
                                <h4>10. ACCEPTABLE USAGE POLICY</h4>
                                <p>10.1 You may only use Our Site in a manner that is lawful. Specifically:<br />
                                    10.1.1 you must ensure that you comply fully with any and all local, national or<br />
                                    international laws and/or regulations;<br />
                                    10.1.2 you must not use Our Site in any way, or for any purpose, that is unlawful or<br />
                                    fraudulent;<br />
                                    10.1.3 you must not use Our Site to knowingly send, upload, or in any other way<br />
                                    transmit data that contains any form of virus or other malware, or any other
                                    code<br />
                                    designed to adversely affect computer hardware, software, or data of any kind;<br />
                                    and<br />
                                    10.1.4 you must not use Our Site in any way, or for any purpose, that is intended to<br />
                                    harm any person or persons in any way.<br />
                                    10.2 We reserve the right to suspend or terminate your access to Our Site if you<br />
                                    materially breach the provisions of this Clause 10 or any of the other provisions of<br />
                                    these Terms and Conditions. Specifically, We may take one or more of the
                                    following<br />
                                    actions:<br />
                                    10.2.1 suspend, whether temporarily or permanently, your right to access Our<br />
                                    Site;<br />
                                    10.2.2 issue you with a written warning;<br />
                                    10.2.3 take legal proceedings against you for reimbursement of any and all
                                    relevant<br />
                                    costs on an indemnity basis resulting from your breach;<br />
                                    10.2.4 take further legal action against you as appropriate;<br />
                                    10.2.5 disclose such information to law enforcement authorities as required or as We<br />
                                    deem reasonably necessary; and/or<br />
                                    10.2.6 any other actions which We deem reasonably appropriate (and lawful).<br />
                                    10.3 We hereby exclude any and all liability arising out of any actions
                                    (including,<br />
                                    but not limited to those set out above) that We may take in response to breaches
                                    of<br />
                                    these Terms and Conditions.</p>
                                <h4>11. PRIVACY AND COOKIES</h4>
                                <p>11.1 Our Site may place and access certain first party Cookies on your computer
                                    or<br />
                                        device. First party Cookies are those placed directly by Us and are used only by
                                        Us.<br />
                                        We use Cookies to facilitate and improve your experience of Our Site and to
                                        provide<br />
                                        and improve Our products and services. We have carefully chosen these Cookies
                                        and<br />
                                        have taken steps to ensure that your privacy and Personal Data is protected
                                        and<br />
                                        respected at all times.<br />
                                        11.2 By using Our Site, you may also receive certain third party Cookies on your<br />
                                        computer or device. Third party Cookies are those placed by websites,
                                        services,<br />
                                        and/or parties other than Us. Third party Cookies are used on Our Site for<br />
                                        marketing services. For more details, please refer to section 11.3.5 below.<br />
                                        11.3 All Cookies used by and on Our Site are used in accordance with current<br />
                                        Cookie Law. We may use some or all of the following types of Cookie:<br />
                                        11.3.1 Strictly Necessary Cookies – A Cookie falls into this category if it
                                        is<br />
                                        essential to the operation of Our Site, supporting functions such as logging in,<br />
                                        your shopping basket, and payment transactions.<br />
                                        11.3.2 Analytics Cookies – It is important for Us to understand how you use
                                        Our<br />
                                        Site, for example, how efficiently you are able to navigate around it, and
                                        what<br />
                                        features you use. Analytics Cookies enable us to gather this information,<br />
                                        helping Us to improve Our Site and your experience of it.<br />
                                        11.3.3 Functionality Cookies – Functionality Cookies enable Us to provide<br />
                                        additional functions to you on Our Site such as personalisation and
                                        remembering<br />
                                        your saved preferences. Some functionality Cookies may also be strictly<br />
                                        necessary Cookies, but not all necessarily fall into that category.<br />
                                        11.3.4 Targeting Cookies – It is important for Us to know when and how often you<br />
                                        visit Our Site, and which parts of it you have used (including which pages
                                        you<br />
                                        have visited and which links you have visited). As with analytics Cookies,
                                        this<br />
                                        information helps us to better understand you and, in turn, to make Our Site and<br />
                                        advertising more relevant to your interests. Some information gathered by<br />
                                        targeting Cookies may also be shared with third parties.<br />
                                        11.3.5 Third Party Cookies – Third party Cookies are not placed by Us;
                                        instead,<br />
                                        they are placed by third parties that provide services to Us and/or to you.<br />
                                        Third party Cookies may be used by advertising services to serve up tailored<br />
                                        advertising to you on Our Site, or by third parties providing analytics services<br />
                                        to Us (these Cookies will work in the same way as analytics Cookies
                                        described<br />
                                        above).<br />
                                        11.3.6 Persistent Cookies – Any of the above types of Cookie may be a persistent<br />
                                        Cookie. Persistent Cookies are those which remain on your computer or device for<br />
                                        a predetermined period and are activated each time you visit Our Site.<br />
                                        11.3.7 Session Cookies – Any of the above types of Cookie may be a session<br />
                                        Cookie. Session Cookies are temporary and only remain on your computer or device<br />
                                        from the point at which you visit Our Site until you close your browser. Session<br />
                                        Cookies are deleted when you close your browser.<br />
                                        11.4 Cookies on Our Site are not permanent and will expire after 30 days.<br />
                                        11.5 For more details of the Personal Data that We collect and use, the measures<br />
                                        we have in place to protect Personal Data, your legal rights, and our legal<br />
                                        obligations, please refer to our Privacy Policy here.<br />
                                        11.6 For more specific details of the Cookies that We use, please refer to
                                        the<br />
                                        table below.</p>
                                <h4>12. CONSENT AND CONTROL</h4>
                                <p>12.1 Before Cookies are placed on your computer or device, you will be shown a pop up<br />
                                    requesting your consent to set those Cookies. By giving your consent to the
                                    placing<br />
                                    of Cookies you are enabling Us to provide the best possible experience and
                                    service<br />
                                    to you. You may, if you wish, deny consent to the placing of Cookies unless
                                    those<br />
                                    Cookies are strictly necessary; however certain features of Our Site may not<br />
                                    function fully or as intended. You will be given the opportunity to allow and/or<br />
                                    deny different categories of Cookie that We use.<br />
                                    12.2 In addition to the controls that We provide, you can choose to enable or<br />
                                    disable Cookies in your internet browser. Most internet browsers also enable you<br />
                                    to choose whether you wish to disable all Cookies or only third party Cookies.<br />
                                    By default, most internet browsers accept Cookies but this can be changed. For<br />
                                    further details, please consult the help menu in your internet browser or the<br />
                                    documentation that came with your device.<br />
                                    12.3 The links below provide instructions on how to control Cookies in all<br />
                                    mainstream browsers:<br />
                                    12.3.1 Google Chrome:<br />
                                    https://support.google.com/chrome/answer/95647?hl=en-GB<br />
                                    12.3.2 Microsoft Internet Explorer:<br />
                                    https://support.microsoft.com/en-us/kb/278835<br />
                                    12.3.3 Microsoft Edge:<br />
                                    https://support.microsoft.com/en-gb/products/microsoft-edge (Please note that<br />
                                    there are no specific instructions at this time, but Microsoft support will be<br />
                                    able to assist)<br />
                                    12.3.4 Safari (macOS):<br />
                                    https://support.apple.com/kb/PH21411?viewlocale=en_GB&amp;locale=en_GB<br />
                                    12.3.5 Safari (iOS): https://support.apple.com/en-gb/HT201265<br />
                                    12.3.6 Mozilla Firefox:<br />
                                    https://support.mozilla.org/en-US/kb/enable-and-disable-Cookies-website-preferences<br />
                                    12.3.7 Android:<br />
                                    https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&amp;hl=en<br />
                                    (Please refer to your device’s documentation for manufacturers’ own browsers)</p>
                                <h4>13. CHANGES TO OUR PRIVACY POLICY</h4>
                                <p>13.1 We may alter this Cookie Policy at any time. Any such changes will become<br />
                                    binding on you on your first use of Our Site after the changes have been made.
                                    You<br />
                                    are therefore advised to check this page from time to time.<br />
                                    13.2 In the event of any conflict between the current version of this Cookie<br />
                                    Policy and any previous version(s), the provisions current and in effect shall<br />
                                    prevail unless it is expressly stated otherwise.</p>
                                <h4>14. WHAT COOKIES DOES OUR SITE USE?</h4>
                                <p>Our Site may place and access certain first-party Cookies on your computer or device.<br />
                                    First-party Cookies are those placed directly by us and are used only by us. We
                                    use<br />
                                    Cookies to facilitate and improve your experience of Our Site and to provide and<br />
                                    improve our products and services. By using Our Site you may also receive
                                    certain<br />
                                    third-party Cookies on your computer or device. Third-party Cookies are those placed<br />
                                    by websites, services, and/or parties other than us. Third-party Cookies are used on<br />
                                    Our Site for marketing services. In addition, Our Site uses analytics services<br />
                                    provided by Google, which also use Cookies. Website analytics refers to a set of<br />
                                    tools used to collect and analyse usage statistics, enabling us me to better<br />
                                    understand how people use Our Site.</p>
                                <h4>15. CONTACTING US FOR FURTHER INFORMATION</h4>
                                <p>15.1 If you would like to know more about data protection or how we use Cookies,<br />
                                    please contact us at contact@32squared.co.uk.</p>
                                <h4>16. CHANGES TO THESE TERMS AND CONDITIONS</h4>
                                <p>16.1 We may alter these Terms and Conditions at any time. Any such changes will<br />
                                    become binding on you upon your first use of Our Site after the changes have
                                    been<br />
                                    implemented. You are therefore advised to check this page from time to time.<br />
                                    16.2 In the event of any conflict between the current version of these Terms and<br />
                                    Conditions and any previous version(s), the provisions current and in effect<br />
                                    shall prevail unless it is expressly stated otherwise.</p>
                                <h4>17. DATA PROTECTION</h4>
                                <p>17.1 Any and all Personal Data that We may collect will be collected, used and
                                    held<br />
                                        in accordance with the provisions of the GDPR and your rights and Our
                                        obligations<br />
                                        under the same.<br />
                                        17.2 We may use your Personal Data to:<br />
                                        17.2.1 Reply to any communications you send to us;<br />
                                        17.3 For more information on our Data Protection Policy, please refer to the<br />
                                        Data Protection Privacy Notice here here.</p>
                                <h4>18. LAW AND JURISDICTION</h4>
                                <p>18.1 These Terms and Conditions, and the relationship between you and Us (whether<br />
                                    contractual or otherwise) shall be governed by and construed in accordance with
                                    the<br />
                                    laws of England and Wales.<br />
                                    18.3 If you are a consumer, any dispute, controversy, proceedings or claim<br />
                                    between you and Us relating to these Terms and Conditions, or the relationship<br />
                                    between you and Us (whether contractual or otherwise) shall be subject to the<br />
                                    jurisdiction of the courts of England and Wales.<br />
                                    18.4 If you are a business, any disputes concerning these Terms and Conditions,<br />
                                    the relationship between you and Us, or any matters arising therefrom or<br />
                                    associated therewith (whether contractual or otherwise) shall be subject to the<br />
                                    exclusive jurisdiction of the courts of England and Wales.</p>

                            </div>

                        </div>

                        <div className={this.props.open.cookie ? "" : "d-none"}>

                            <div>
                                <h1>Cookie Policy</h1>
                                <p>Thank you for visiting ableplan.uk. When visiting our site we may have transferred a
                                    cookie to your computer. A cookie is a small piece of information sent from this
                                    website to your browser for storage on your computer. If you want to know more about
                                    cookies there is an explanation on Wikipedia here HTTP Cookies.</p>
                                <p><strong>At ableplan we use Cookies in the following ways:</strong></p>
                                <ul>
                                    <li>The software that runs this site may transfer a temporary cookie so it can keep
                                        track of what it’s doing during the ordering process.
                                    </li>
                                    <li>To remarket services on third party websites (including Google) to previous
                                        visitors to our site using Google AdWords remarketing.
                                    </li>
                                    <li>To track visitors to our site using Google Analytics, a popular service that is
                                        provided by Google. We use Google Analytics to analyse how visitors use our site
                                        and to ultimately improve our service. To find out more about these cookies
                                        please see <a href="https://support.google.com/analytics/answer/6004245"
                                                      target="_blank" rel="nofollow noopener noreferrer">Google’s Privacy Policy</a>.
                                    </li>
                                </ul>
                                <h2>Your Privacy</h2>
                                <ul>
                                    <li>Cookies used on this site do not contain any personal information.</li>
                                    <li>Any information collected is anonymous and generalised.</li>
                                    <li>Your privacy is respected at all times.</li>
                                    <li><a href="//ableplan.uk/privacy-policy/">See our full Privacy Policy</a></li>
                                </ul>
                                <h2>Cookie Removal</h2>
                                <p>If you wish to know how to remove cookies then <a href="http://AboutCookies.org"
                                                                                     target="_blank"
                                                                                     rel="nofollow noopener noreferrer">AboutCookies.org</a> should
                                    be of help.</p>

                            </div>

                        </div>

                        <div className={this.props.open.privacy ? "" : "d-none"}>

                            <div>
                                <h1>Privacy Policy</h1>
                                <h4>BACKGROUND:</h4>
                                <p>32 Squared Media Ltd understands that your privacy is important to you and that
                                    you<br />
                                        care about how your personal data is used and shared online. We respect and
                                        value<br />
                                        the privacy of everyone who visits this website, www.ableplan.uk (“Our Site”)
                                        and<br />
                                        will only collect and use personal data in ways that are described here, and in
                                        a<br />
                                        manner that is consistent with Our obligations and your rights under the law.
                                </p>
                                <p>Please read this Privacy Policy carefully and ensure that you understand it. Your<br />
                                    acceptance of Our Privacy Policy is deemed to occur upon your first use of Our Site.<br />
                                    If you do not accept and agree with this Privacy Policy, you must stop using Our<br />
                                    Site immediately.</p>
                                <h4>1. DEFINITIONS AND INTERPRETATION</h4>
                                <p>In this Policy the following terms shall have the following meanings:</p>
                                <p><strong>“Cookie”</strong><br />
                                    means a small text file placed on your computer or device by Our Site when you visit<br />
                                    certain parts of Our Site and/or when you use certain features of Our Site.
                                    Details<br />
                                    of the Cookies used by Our Site are set out in section 13, below;</p>
                                <p><strong>“Cookie Law”</strong><br />
                                    means the relevant parts of the Privacy and Electronic Communications (EC Directive)<br />
                                    Regulations 2003;</p>
                                <p><strong>“Personal Data”</strong><br />
                                    means any and all data that relates to an identifiable person who can be directly or<br />
                                    indirectly identified from that data. In this case, it means Personal Data that
                                    you<br />
                                    give to Us via Our Site. This definition shall, where applicable, incorporate
                                    the<br />
                                    definitions provided in the EU Regulation 2016/679 – the General Data Protection<br />
                                    Regulation (“GDPR”); and</p>
                                <p><strong>“We/Us/Our/the Company”</strong><br />
                                    Means 32 Squared Media Ltd, Company Number 09095076.<br />
                                    Registered Address: Henleaze Business Centre, 13 Harbury Road, Henleaze, Bristol BS9 4PN</p>
                                <h4>2. INFORMATION ABOUT US</h4>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td valign="top" width="50">2.1</td>
                                        <td>Our Site is owned and operated by the Company.</td>
                                    </tr>
                                    <tr>
                                        <td valign="top" width="50">2.2</td>
                                        <td>Our Data Protection Officer can be contacted at
                                            contact@32squared.co.uk.<br />
                                                Data is collected, processed and maintained in accordance with the
                                                Data<br />
                                                Protection Act 1998. Registration Number: ZB311111</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <h4>3. WHAT DOES THIS POLICY COVER?</h4>
                                <p>This Privacy Policy applies only to your use of Our Site. Our Site may contain
                                    links<br />
                                        to other websites. Please note that We have no control over how your data is<br />
                                        collected, stored, or used by other websites and We advise you to check the
                                        privacy<br />
                                        policies of any such websites before providing any data to them.</p>
                                <h4>4. YOUR RIGHTS</h4>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td valign="top" width="50">4.1</td>
                                        <td>As a data subject, you have the following rights under the GDPR, which
                                            this<br />
                                                Policy and Our use of personal data have been designed to uphold:<p></p>
                                                <table>
                                                    <tbody>
                                                    <tr>
                                                        <td valign="top" width="50">4.1.1</td>
                                                        <td>The right to be informed about Our collection and use of<br />
                                                            personal data;</td>
                                                    </tr>
                                                    <tr>
                                                        <td valign="top" width="50">4.1.2</td>
                                                        <td>The right of access to the personal data We hold about you
                                                            (see<br />
                                                                section 12);</td>
                                                    </tr>
                                                    <tr>
                                                        <td valign="top" width="50">4.1.3</td>
                                                        <td>The right to rectification if any personal data We hold
                                                            about<br />
                                                                you is inaccurate or incomplete (please contact Us using
                                                                the<br />
                                                                details in section 14);</td>
                                                    </tr>
                                                    <tr>
                                                        <td valign="top" width="50">4.1.4</td>
                                                        <td>The right to be forgotten – i.e. the right to ask Us to
                                                            delete<br />
                                                                any personal data We hold about you (We only hold your
                                                                personal<br />
                                                                data for a limited time, as explained in section 6 but
                                                                if you<br />
                                                                would like Us to delete it sooner, please contact Us
                                                                using the<br />
                                                                details in section 14);</td>
                                                    </tr>
                                                    <tr>
                                                        <td valign="top" width="50">4.1.5</td>
                                                        <td>The right to restrict (i.e. prevent) the processing of
                                                            your<br />
                                                                personal data;</td>
                                                    </tr>
                                                    <tr>
                                                        <td valign="top" width="50">4.1.6</td>
                                                        <td>The right to data portability (obtaining a copy of your
                                                            personal<br />
                                                                data to re-use with another service or organisation);
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td valign="top" width="50">4.1.7</td>
                                                        <td>The right to object to Us using your personal data for<br />
                                                            particular purposes; and</td>
                                                    </tr>
                                                    <tr>
                                                        <td valign="top" width="50">4.1.8</td>
                                                        <td>Rights with respect to automated decision making and<br />
                                                            profiling.</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top" width="50">4.2</td>
                                        <td>If you have any cause for complaint about our use of your personal data,<br />
                                            please contact us using the details provided in section 14 and we will
                                            do<br />
                                            our best to resolve the problem for you. If we are unable to help, you
                                            also<br />
                                            have the right to lodge a complaint with the UK’s supervisory authority, the<br />
                                            Information Commissioner’s Office.</td>
                                    </tr>
                                    <tr>
                                        <td valign="top" width="50">4.3</td>
                                        <td>For further information about your rights, please contact the
                                            Information<br />
                                                Commissioner’s Office or your local Citizens Advice Bureau.</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <h4>5. WHAT DATA DO WE COLLECT?</h4>
                                <p>Depending upon your use of Our Site, We may collect some or all of the following<br />
                                    personal and non-personal data (please also see section 13 on our use of Cookies and<br />
                                    similar technologies and Our Cookie Policy):</p>
                                <p>name;<br />
                                    date of birth;<br />
                                    contact information such as email addresses and telephone numbers;<br />
                                    demographic information such as post code;<br />
                                    IP address;<br />
                                    web browser type and version;<br />
                                    operating system;<br />
                                    a list of URLs starting with a referring site, your activity on Our Site, and
                                    the<br />
                                    site you exit to;</p>
                                <h4>6. HOW DO WE USE YOUR DATA?</h4>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td valign="top" width="50">6.1</td>
                                        <td>All personal data is processed and stored securely, for no longer than
                                            is<br />
                                                necessary in light of the reason(s) for which it was first collected. We<br />
                                                will comply with Our obligations and safeguard your rights under GDPR at
                                                all<br />
                                                times. For more details on security see section 8, below.</td>
                                    </tr>
                                    <tr>
                                        <td valign="top" width="50">6.2</td>
                                        <td>Our use of your personal data will always have a lawful basis, either<br />
                                            because it is necessary for Our performance of a contract with you,
                                            because<br />
                                            you have consented to Our use of your personal data (e.g. by subscribing
                                            to<br />
                                            emails), or because it is in Our legitimate interests. Specifically, We
                                            may<br />
                                            use your data for the following purposes:<p></p>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td valign="top" width="50">6.2.1</td>
                                                    <td>Supplying Our services to you (please note that We require
                                                        your<br />
                                                            personal data in order to enter into a contract with you);
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td valign="top" width="50">6.2.2</td>
                                                    <td>Personalising and tailoring Our services for you;</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top" width="50">6.2.3</td>
                                                    <td>Replying to emails from you;</td>
                                                </tr>
                                                <tr>
                                                    <td valign="top" width="50">6.2.4</td>
                                                    <td>Analysing your use of Our Site and gathering feedback to
                                                        enable<br />
                                                            Us to continually improve Our Site and your user experience;
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td valign="top" width="50">6.2.5</td>
                                                    <td>With your permission and/or where permitted by law, we may
                                                        also<br />
                                                            use your data for marketing purposes which may include<br />
                                                            contacting you by email or telephone with information, news
                                                            and<br />
                                                            offers on services. We will not, however, send you any<br />
                                                            unsolicited marketing or spam and will take all reasonable
                                                            steps<br />
                                                            to ensure that we fully protect your rights and comply with
                                                            our<br />
                                                            obligations under GDPR and the Privacy and Electronic<br />
                                                            Communications (EC Directive) Regulations 2003.</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top" width="50">6.3</td>
                                        <td>You have the right to withdraw your consent to us using your personal
                                            data<br />
                                                at any time, and to request that we delete it.</td>
                                    </tr>
                                    <tr>
                                        <td valign="top" width="50">6.4</td>
                                        <td>We do not keep your personal data for any longer than is necessary in
                                            light<br />
                                                of the reason(s) for which it was first collected. Data will therefore
                                                be<br />
                                                retained for the following periods (or its retention will be determined
                                                on<br />
                                                the following bases):<br />
                                                24 Months from the date of the last submission of data.</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <h4>7. DO WE SHARE YOUR DATA?</h4>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td valign="top" width="50">7.1</td>
                                        <td>We will contract a third party to supply products and services to you on Our
                                            behalf. These may include payment processing, delivery of goods, search
                                            engine facilities, advertising, and marketing. In some cases, the third
                                            parties may require access to some or all of your data. Where any of your
                                            data is required for such a purpose, We will take all reasonable steps to
                                            ensure that your data will be handled safely, securely, and in accordance
                                            with your rights, Our obligations, and the obligations of the third party
                                            under the law.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top" width="50">7.2</td>
                                        <td>We may compile statistics about the use of Our Site including data on<br />
                                            traffic, usage patterns, user numbers, sales, and other information. All<br />
                                            such data will be anonymised and will not include any personally identifying<br />
                                            data, or any anonymised data that can be combined with other data and
                                            used<br />
                                            to identify you. We may from time to time share such data with third parties<br />
                                            such as prospective investors, affiliates, partners, and advertisers.
                                            Data<br />
                                            will only be shared and used within the bounds of the law.</td>
                                    </tr>
                                    <tr>
                                        <td valign="top" width="50">7.3</td>
                                        <td>In certain circumstances, we may be legally required to share certain
                                            data<br />
                                                held by us, which may include your personal data, for example, where we
                                                are<br />
                                                involved in legal proceedings, where we are complying with legal<br />
                                                requirements, a court order, or a governmental authority.</td>
                                    </tr>
                                    <tr>
                                        <td valign="top" width="50">7.4</td>
                                        <td>You have the right to withdraw your consent to Us using your personal
                                            data<br />
                                                at any time, and to request that We delete it.</td>
                                    </tr>
                                    <tr>
                                        <td valign="top" width="50">7.5</td>
                                        <td>We do not keep your personal data for any longer than is necessary in
                                            light<br />
                                                of the reason(s) for which it was first collected.</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <h4>8. HOW AND WHERE DO WE STORE YOUR DATA?</h4>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td valign="top" width="50">8.1</td>
                                        <td>We only keep your personal data for as long as We need to in order to use it<br />
                                            as described above in section 6, and/or for as long as We have your<br />
                                            permission to keep it.</td>
                                    </tr>
                                    <tr>
                                        <td valign="top" width="50">8.2</td>
                                        <td>Your data will only be stored within the European Economic Area (“the
                                            EEA”)<br />
                                                (The EEA consists of all EU member states, plus Norway, Iceland, and<br />
                                                Liechtenstein). Data security is very important to Us, and to protect
                                                your<br />
                                                data We have taken suitable measures to safeguard and secure data
                                                collected<br />
                                                through Our Site.</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <h4>9. WHAT HAPPENS IF OUR BUSINESS CHANGES HANDS?</h4>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td valign="top" width="50">9.1</td>
                                        <td>We may, from time to time, expand or reduce Our business and this may<br />
                                            involve the sale and/or the transfer of control of all or part of Our<br />
                                            business. Any personal data that you have provided will, where it is<br />
                                            relevant to any part of Our business that is being transferred, be<br />
                                            transferred along with that part and the new owner or newly controlling<br />
                                            party will, under the terms of this Privacy Policy, be permitted to use that<br />
                                            data only for the same purposes for which it was originally collected by Us.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top" width="50">9.2</td>
                                        <td>In the event that any of your data is to be transferred in such a
                                            manner,<br />
                                                you will be contacted in advance and informed of the changes. When
                                                contacted<br />
                                                you will be given the choice to have your data deleted or withheld from
                                                the<br />
                                                new owner or controller.</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <h4>10. HOW CAN YOU CONTROL YOUR DATA?</h4>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td valign="top" width="50">10.1</td>
                                        <td>In addition to your rights under the GDPR, set out in section 4, when
                                            you<br />
                                                submit personal data via Our Site, you may be given options to restrict
                                                Our<br />
                                                use of your data. In particular, We aim to give you strong controls on
                                                Our<br />
                                                use of your data for direct marketing purposes (including the ability to<br />
                                                opt-out of receiving emails from Us which you may do by unsubscribing
                                                using<br />
                                                the links provided in Our emails and at the point of providing your<br />
                                                details).</td>
                                    </tr>
                                    <tr>
                                        <td valign="top" width="50">10.2</td>
                                        <td>You may also wish to sign up to one or more of the preference services<br />
                                            operating in the UK: The Telephone Preference Service (“the TPS”), the<br />
                                            Corporate Telephone Preference Service (“the CTPS”), and the Mailing<br />
                                            Preference Service (“the MPS”). These may help to prevent you receiving<br />
                                            unsolicited marketing. Please note, however, that these services will
                                            not<br />
                                            prevent you from receiving marketing communications that you have
                                            consented<br />
                                            to receiving.</td>
                                    </tr>
                                    </tbody>
                                </table>
                                <h4>11. YOUR RIGHT TO WITHHOLD INFORMATION</h4>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td valign="top" width="50">11.1</td>
                                        <td>You may access Our Site without providing any data at all. However, to
                                            use<br />
                                                all features and functions available on Our Site you may be required
                                                to<br />
                                                submit or allow for the collection of certain data.</td>
                                    </tr>
                                    <tr>
                                        <td valign="top" width="50">11.2</td>
                                        <td>You may restrict Our use of Cookies. For more information, see section 13.
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <h4>12. HOW CAN YOU ACCESS YOUR DATA?</h4>
                                <p>You have the right to ask for a copy of any of your personal data held by Us
                                    (where<br />
                                        such data is held) Under the GDPR, no fee is payable and We will provide any and
                                        all<br />
                                        information in response to your request free of charge. Please contact Us using
                                        the<br />
                                        contact details below in section 14.</p>
                                <h4>13. OUR USE OF COOKIES</h4>
                                <p>We use Cookies to help us improve your experience when you browse on Our Site and<br />
                                    also to analyse how you use it, so we can improve it in the future. For detailed<br />
                                    information about Cookies, how we use them and the applicable Cookie Law, please<br />
                                    refer to Our Terms and Conditions which can be found here.</p>
                                <h4>14. CONTACTING US</h4>
                                <p>If you have any questions about Our Site or this Privacy Policy, please contact Us by<br />
                                    email at contact@32squared.co.uk. Please ensure that your query is clear,<br />
                                    particularly if it is a request for information about the data We hold about you (as<br />
                                    under section 12, above).</p>
                                <h4>15. CHANGES TO OUR PRIVACY POLICY</h4>
                                <p>We may change this privacy policy from time to time (for example, if the law<br />
                                    changes). Any changes will be immediately posted on our site and you will be
                                    deemed<br />
                                    to have accepted the terms of the privacy policy on your first use of our site<br />
                                    following the alterations. We recommend that you check this page regularly to
                                    keep<br />
                                    up-to-date.</p>
                                <h4>16. COMPLAINTS</h4>
                                <p>Our aim is not to give you cause to complain. However, if you feel you do have
                                    cause<br />
                                        to complain, you are welcome to submit your concerns to us by email at . Under
                                        the<br />
                                        GDPR you have the right to complain to Information Commissioner’s Office<br />
                                        (www.ico.org.uk). We treat any complaints very seriously and we would appreciate
                                        the<br />
                                        opportunity to try to resolve any issues in the first instance.</p>

                            </div>

                        </div>



                    </div>

                </div>



            </React.Fragment>
        )
    }

}

export default Footer;
