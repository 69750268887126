import React, {Component} from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Sales1 from "./components/Sales1";
import Form1 from "./components/forms/Form1";
import axios from 'axios';
import icon from "./img/icon.png";
import Presale from "./components/Presale";
//import ReactPixel from 'react-facebook-pixel'
//import ReactGA from 'react-ga';
import {BrowserRouter as Router, Route, Switch, Redirect, withRouter} from "react-router-dom";

import logos from "./img/er-logos.png";
import * as utils from "./utils/utils";
import Thankyou from "./components/Thankyou";
import PageNotFound from "./components/PageNotFound";

class App extends Component{

    state = {
        key: 'a2c9044d56ec953736870e8b1ae1df33',
        //key: 'f771358d5e0c09278adf4ef0b3423c50',
        campid: 'EQUITY-RELEASELIVE',
        ipaddress: '69.69.69.69',
        processing: false,
        searching: false,
        errors: [],
        errorCache: [],
        activeFields: [],
        formStage: 1,
        notSearched: true,
        formProcessed: false,
        buyerData: {},
        open: {
            main: false,
            terms: false,
            privacy: false,
            cookie: false,
        },
        submitedData: {},
        calcMax: false,
        calcAmount: '',
    };

    constructor(){
        super();

        // //Facebook pixle
        // ReactPixel.init('1066307436774128');
        // ReactPixel.pageView();
        //
        // //GA code
        // ReactGA.initialize('UA-53537965-2');
        // ReactGA.initialize('AW-930956384');
        // let url = window.location.pathname;
        // ReactGA.pageview(url);

        //Get users IP
        axios.get('https://api.ipify.org')
            .then(
                (result) => {
                    this.setState({
                        ipaddress : result.data,
                    })
                });
    }

    render() {

        return (
            <div className="App">

                <Header></Header>

                {/*<Router>*/}
                    <Switch>

                        <Route exact path="/" render={() => (
                            this.state.formProcessed ? (
                                <Redirect to="/thank-you"/>
                            ) : (
                                <this.FormPage v="1" brandLogos="yes" showCalc="no"/>
                            )
                        )}/>

                        <Route exact path="/calculator/nb*" render={() => (
                            this.state.formProcessed ? (
                                <Redirect to="/thank-you"/>
                            ) : (
                                <this.FormPage v="1" brandLogos="no" showCalc="yes"/>
                            )
                        )}/>

                        <Route exact path="/calculator/v2/nb*" render={() => (
                            this.state.formProcessed ? (
                                <Redirect to="/thank-you"/>
                            ) : (
                                <this.FormPage v="2" brandLogos="no" showCalc="yes"/>
                            )
                        )}/>

                        <Route exact path="/calculator/v2*" render={() => (
                            this.state.formProcessed ? (
                                <Redirect to="/thank-you"/>
                            ) : (
                                <this.FormPage v="2" brandLogos="yes" showCalc="yes"/>
                            )
                        )}/>

                        <Route exact path="/calculator/v3*" render={() => (
                            this.state.formProcessed ? (
                                <Redirect to="/thank-you"/>
                            ) : (
                                <this.FormPage v="3" brandLogos="alt" showCalc="yes"/>
                            )
                        )}/>

                        <Route exact path="/calculator" render={() => (
                            this.state.formProcessed ? (
                                <Redirect to="/thank-you"/>
                            ) : (
                                <this.FormPage v="1" brandLogos="yes" showCalc="yes"/>
                            )
                        )}/>

                        <Route path="/thank-you" render={() => (
                            this.state.formProcessed ? (
                                <Thankyou
                                    calcMax={this.state.calcMax}
                                    calcAmount={this.state.calcAmount}
                                    buyerData={this.state.buyerData}
                                    formProcessed={this.formProcessed}
                                />
                            ) : (
                                <Redirect to="/"/>
                            )
                        )}/>

                        <Route path="*">
                            <PageNotFound />
                        </Route>

                    </Switch>
                {/*</Router>*/}

                <Footer
                    open={this.state.open}
                    closePopup={this.closePopup}
                    openPopup={this.openPopup}
                ></Footer>

            </div>
        );
    }


    closePopup = () => {

        document.body.classList.remove('popup-open');

        this.setState({
            open: {
                main: false,
                terms: false,
                privacy: false,
                cookie: false,
            }
        });

    }

    openPopup = (target) => {
        document.body.classList.add('popup-open');
        this.setState({
            open: {
                main: true,
                [target]: true,
            }
        });
    }

    updatePixels = () => {

        //let currentDate = new Date().getTime();

        // //Google
        // ReactGA.pageview('/thank-you');
        // ReactGA.plugin.require('ecommerce');
        // ReactGA.plugin.execute('ecommerce', 'addTransaction', {
        //     id: 'P' + currentDate,
        //     name:'ER Lead',
        //     revenue: '1.00'
        // });
        // ReactGA.plugin.execute('ecommerce', 'send');
        //
        // ReactGA.event({
        //     category: 'conversion',
        //     action: 'send_to',
        //     label: 'AW-930956384/78BoCMyx94oBEOCI9bsD',
        // });
        //
        //
        // //Zucker
        // ReactPixel.track( 'Lead', {
        //     value: 20,
        //     currency: 'GBP',
        //     content_name: 'ER1',
        //     content_category: 'Equity Release'
        // });

        if(this.state.calcMax){
            let propVal = this.state.submitedData.get('property_value');
            let birthDate = this.state.submitedData.get('dob');
            let msg = utils.calcMaxAmount(propVal, birthDate);
            this.setState({
                calcAmount: msg,
            });
        }

    }

    updateFormStage = (step) => {
        let formStage = this.state.formStage;
        let block = false;


        //Remove searching
        if(formStage === 4) {
            this.setState({
                notSearched: false
            })
        }


        if(formStage === 1 && step > 0) {


            if(!this.state.activeFields.includes('property_value')){
                this.errorUpdater('property_value', true);
                block = true;
            }
            if(!this.state.activeFields.includes('mortgage_amount')){
                this.errorUpdater('mortgage_amount', true);
                block = true;
            }

        } else if(formStage === 2 && step > 0) {

            if(!this.state.activeFields.includes('dobdd')){
                this.errorUpdater('dob', true);
                block = true;
            }

        } else if(formStage === 3 && step > 0) {

            if(!this.state.activeFields.includes('street1')){
                this.errorUpdater('street1', true);
                block = true;
            }
            if(!this.state.activeFields.includes('postcode')){
                this.errorUpdater('postcode', true);
                block = true;
            }

        } else if(formStage === 4 && step > 0) {

            if(!this.state.activeFields.includes('firstname')){
                this.errorUpdater('firstname', true);
                block = true;
            }
            if(!this.state.activeFields.includes('lastname')){
                this.errorUpdater('lastname', true);
                block = true;
            }

        }

        if(!block){

            if(formStage === 3 && step > 0 && this.state.notSearched) {
                this.toggleSearch();
            }

            formStage = formStage + step;
            if(formStage < 6){
                this.setState({formStage});
            }
        }


        //Update URL
        const currentURL = window.location.pathname
        const removeStepURL = currentURL.lastIndexOf('/s') > 0 ? currentURL.substring(0, currentURL.lastIndexOf('/s')) : currentURL;
        const newURL = removeStepURL + "/step" +  formStage
        this.props.history.push(newURL);

    }

    errorUpdater = (field, add = false) => {

        let errors = this.state.errors;

        if(add) {
            errors.push(field);
        } else {
            errors = errors.filter(el => el !== field);
        }
        this.setState({errors})
    }

    activeFields = () => {

        let activeFields = utils.getActiveFields();
        this.setState({activeFields});

    }

    handleSubmit = (e, settings) => {
        e.preventDefault();

        if(settings.showCalc === "yes"){
            this.setState({
                calcMax: true,
            })
        }

        this.validatePhone();

    }

    validatePhone = () =>{
        utils.validatePhoneAXIOS().then(data => {
            //console.log(data);
            let convert = require('xml-js');
            let finalResult = convert.xml2json(data, {compact: true, spaces: 4});
            finalResult = JSON.parse(finalResult);

            //console.log(finalResult.RESPONSE.STATUS._text);
            let phoneOK = finalResult.RESPONSE.STATUS._text === 'ERROR' ? false : true;
            this.sendData(phoneOK);

        }).catch(error => {
            //console.log(error)
            this.sendData();
        });

    }


    sendData = (phoneOK = true) => {

        let block = false;

        if(!phoneOK){
            this.errorUpdater('phone', true);
            block = true;
        }

        if(!this.state.activeFields.includes('email')){
            this.errorUpdater('email', true);
            block = true;
        }

        if(block){
            return
        }

        this.toggleProcessor(true);

        //Clear old errors
        this.setState({
            errors: [],
        });

        const submitUrl = 'https://go.webformsubmit.com/32squared/waitsubmit';
        //const submitUrl = 'https://clarion.leadbyte.co.uk/api/submit.php ';
        const formElement = document.forms.namedItem("mainForm");
        const data = new FormData(formElement);



        //Set values not on form
        data.append('key', this.state.key);
        data.append('ipaddress', this.state.ipaddress);
        data.append('campid', this.state.campid);

        //Set DOB
        let dob = data.get('dobdd') + '/' + data.get('dobmm') + '/' + data.get('dobyyyy');
        data.append('dob', dob);

        //console.log(dob);

        axios.post(submitUrl, data)
            .then(
                (result) => {
                    this.dealWithData(result.data);
                    this.toggleProcessor(false);
                },
                (error) => {
                    console.log('error============:', error);
                    this.toggleProcessor(false);
                }
            );

        this.setState({
            submitedData: data,
        });

    }



    toggleProcessor = (state) => {
        if(state){
            document.body.classList.add('processing-open');
        } else {
            document.body.classList.remove('processing-open');
        }
        //Open processing box
        this.setState({
            processing: state,
        });
    }

    toggleSearch = () => {

        document.body.classList.add('processing-open');
        //Open processing box
        this.setState({
            searching: true,
        });

        setTimeout(function(){
            document.body.classList.remove('processing-open');
            this.setState({
                searching:false
            });
        }.bind(this),1500);

    }

    dealWithData = (data) => {

        //console.log('success====:', data);

        switch (data.code) {

            //**********************
            // Lead errors
            //**********************
            case -5:
                data.errors.forEach((error) => {
                    if(utils.errorMapping[error]){
                        let field = utils.errorMapping[error];
                        let errors = this.state.errors;
                        //errors[field] = true;

                        errors.push(field);

                        this.setState({
                            errors: errors,
                            errorCache: errors,
                        });

                    } else {
                        //can't find mapping, [EMAIL]
                    }
                });
                break;

            //**********************
            // Duplicate or resubmission
            //**********************
            case -2:

                this.setState({
                    formProcessed: true,
                });
                break;

            //**********************
            // Lead accepted
            //**********************
            case 1:

                this.updatePixels();

                let buyerData = utils.getTheBuyer(data);
                this.setState({buyerData});

                this.setState({
                    formProcessed: true,
                });
                break;

            //**********************
            // Unknown error
            //**********************
            default:

                // [EMAIL]

                break;

        }

    }


    FormPage = (settings) => {

        //console.log(settings.brandLogos);
        return (
            <div>
                <section className="main-form-container">
                    <Presale version={settings.v}></Presale>
                    <div className="container">
                        <form onSubmit={event => this.handleSubmit(event, {showCalc: settings.showCalc}) } onChange={this.activeFields} className="main-form" name="mainForm" noValidate>

                            {settings.brandLogos === 'alt' &&
                                <div className="main-form-container__logos">
                                    <p>Compare deals from the leading providers</p>
                                    <img src={logos} alt="Compare deals from the leading providers"/>
                                </div>
                            }

                            {(settings.v === '2' || settings.v === '3') && this.state.formStage === 1 &&
                                <h4 className="form-title">
                                    Let's get started
                                </h4>
                            }
                            {(settings.v === '2' || settings.v === '3') && this.state.formStage === 5 &&
                                <h4 className="form-title">
                                    Final step
                                </h4>
                            }
                            <div className="progress mb-4">
                                <div className="progress-bar progress-bar-striped"
                                     role="progressbar"
                                     style={
                                         {
                                             width: ((this.state.formStage - 1) * 100 / 5) + '%',
                                         }
                                     }
                                >
                                </div>
                                {settings.v === '2' &&
                                <span className="progress__num">Progress: {Math.round((this.state.formStage - 1) * 100 / 5)}%</span>
                                }
                            </div>

                            <Form1
                                errors={this.state.errors}
                                errorCache={this.state.errorCache}
                                errorUpdater={this.errorUpdater}
                                toggleSearch={this.toggleSearch}
                                activeFields={this.state.activeFields}
                                formStage={this.state.formStage}
                                updateFormStage={this.updateFormStage}
                                notSearched={this.state.notSearched}
                                openPopup={this.openPopup}
                                version={settings.v}
                                showCalc={settings.showCalc}
                                //brandLogos={settings.brandLogos}
                            ></Form1>



                            <div className={this.state.processing ? "main-form__processing main-form__processing--active" : "main-form__processing" }>
                                <div>
                                    <img src={icon} className="main-form__processing__icon" alt="processing"/>
                                    <p>Processing ...</p>
                                </div>
                            </div>


                            <div className={this.state.searching ? "main-form__processing main-form__processing--active" : "main-form__processing" }>
                                <div>
                                    <img src={icon} className="main-form__processing__icon" alt="processing"/>
                                    {settings.showCalc === "no" &&
                                        <p>Searching ...</p>
                                    }
                                    {settings.showCalc === "yes" &&
                                        <p>Calculating ...</p>
                                    }

                                </div>
                            </div>

                        </form>
                    </div>


                    {(settings.v === "2" || settings.v === "3") &&
                    <div className="main-form-container__points__body main-form-container__points__body--2">
                        <h2>What could equity release do for you?</h2>
                        <ul>
                            <li><i className="fas fa-check-circle"></i> Tax-free funds from your property</li>
                            <li><i className="fas fa-check-circle"></i> Gift an early inheritance</li>
                            <li><i className="fas fa-check-circle"></i> Pay off existing mortgage or credit cards</li>
                        </ul>
                    </div>
                    }



                </section>

                <Sales1
                    version={settings.v}
                    brandLogos={settings.brandLogos}
                ></Sales1>
            </div>
        );

    }

}
export default withRouter(App);
