import React, {Component} from 'react';
import logo from "../img/logo.png";

class Header extends Component{

    render() {

        return(
            <React.Fragment>

                <header className="main-header">

                    <div className="container">
                        <img src={logo} className="main-header__logo" alt="logo" />
                    </div>

                </header>



            </React.Fragment>
        )
    }

}

export default Header;
