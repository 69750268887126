import React, {Component} from 'react';

class MortgageAmount extends Component {
    render() {
        return(
            <React.Fragment>
                <select
                    type="text"
                    name="mortgage_amount"
                    id="mortgage_amount"
                    className={this.props.error ? "form-control is-invalid" : "form-control"}
                    onChange={() => this.props.errorUpdater('mortgage_amount')}
                    required
                >
                        <option value="">Mortgage amount</option>
                        <option value="0">Nil</option>
                        <option value="49999">Less Than £50,000</option>
                        <option value="52500">£50,000 - £54,999</option>
                        <option value="57500">£55,000 - £59,999</option>
                        <option value="62500">£60,000 - £64,999</option>
                        <option value="67500">£65,000 - £69,999</option>
                        <option value="72500">£70,000 - £74,999</option>
                        <option value="77500">£75,000 - £79,999</option>
                        <option value="82500">£80,000 - £84,999</option>
                        <option value="87500">£85,000 - £90,999</option>
                        <option value="92500">£90,000 - £94,999</option>
                        <option value="97500">£95,000 - £99,999</option>
                        <option value="102500">£100,000 - £104,999</option>
                        <option value="107500">£105,000 - £109,999</option>
                        <option value="112500">£110,000 - £114,999</option>
                        <option value="117500">£115,000 - £119,999</option>
                        <option value="122500">£120,000 - £124,999</option>
                        <option value="127500">£125,000 - £129,999</option>
                        <option value="132500">£130,000 - £134,999</option>
                        <option value="137500">£135,000 - £139,999</option>
                        <option value="142500">£140,000 - £144,999</option>
                        <option value="147500">£145,000 - £149,999</option>
                        <option value="152500">£150,000 - £154,999</option>
                        <option value="157500">£155,000 - £159,999</option>
                        <option value="162500">£160,000 - £164,999</option>
                        <option value="167500">£165,000 - £169,999</option>
                        <option value="172500">£170,000 - £174,999</option>
                        <option value="177500">£175,000 - £179,999</option>
                        <option value="182500">£180,000 - £184,999</option>
                        <option value="187500">£185,000 - £189,999</option>
                        <option value="192500">£190,000 - £194,999</option>
                        <option value="197500">£195,000 - £199,999</option>
                        <option value="205000">£200,000 - £209,999</option>
                        <option value="215000">£210,000 - £219,999</option>
                        <option value="225000">£220,000 - £229,999</option>
                        <option value="235000">£230,000 - £239,999</option>
                        <option value="245000">£240,000 - £249,999</option>
                        <option value="255000">£250,000 - £259,999</option>
                        <option value="265000">£260,000 - £269,999</option>
                        <option value="275000">£270,000 - £279,999</option>
                        <option value="285000">£280,000 - £289,999</option>
                        <option value="295000">£290,000 - £299,999</option>
                        <option value="305000">£300,000 - £309,999</option>
                        <option value="315000">£310,000 - £319,999</option>
                        <option value="325000">£320,000 - £329,999</option>
                        <option value="335000">£330,000 - £339,999</option>
                        <option value="345000">£340,000 - £349,999</option>
                        <option value="355000">£350,000 - £359,999</option>
                        <option value="365000">£360,000 - £369,999</option>
                        <option value="375000">£370,000 - £379,999</option>
                        <option value="385000">£380,000 - £389,999</option>
                        <option value="395000">£390,000 - £399,999</option>
                        <option value="410000">£400,000 - £419,999</option>
                        <option value="430000">£420,000 - £439,999</option>
                        <option value="450000">£440,000 - £459,999</option>
                        <option value="470000">£460,000 - £479,999</option>
                        <option value="490000">£480,000 - £499,999</option>
                        <option value="510000">£500,000 - £519,999</option>
                        <option value="530000">£520,000 - £539,999</option>
                        <option value="550000">£540,000 - £559,999</option>
                        <option value="570000">£560,000 - £579,999</option>
                        <option value="590000">£580,000 - £599,999</option>
                        <option value="610000">£600,000 - £619,999</option>
                        <option value="630000">£620,000 - £639,999</option>
                        <option value="650000">£640,000 - £659,999</option>
                        <option value="670000">£660,000 - £679,999</option>
                        <option value="690000">£680,000 - £699,999</option>
                        <option value="710000">£700,000 - £719,999</option>
                        <option value="730000">£720,000 - £739,999</option>
                        <option value="750000">£740,000 - £759,999</option>
                        <option value="770000">£760,000 - £779,999</option>
                        <option value="790000">£780,000 - £799,999</option>
                        <option value="810000">£800,000 - £819,999</option>
                        <option value="830000">£820,000 - £839,999</option>
                        <option value="850000">£840,000 - £859,999</option>
                        <option value="870000">£860,000 - £879,999</option>
                        <option value="890000">£880,000 - £899,999</option>
                        <option value="910000">£900,000 - £919,999</option>
                        <option value="930000">£920,000 - £939,999</option>
                        <option value="950000">£940,000 - £959,999</option>
                        <option value="970000">£960,000 - £979,999</option>
                        <option value="990000">£980,000 - £999,999</option>
                        <option value="1000000">Over £1,000,000</option>

                </select>
            </React.Fragment>
        )
    }

}

export default MortgageAmount;
