import axios from "axios";


export const errorMapping = {
    'Email is not a valid email address': 'email',
    'Email is required': 'email',

    'First Name is required': 'firstname',

    'Last Name is required' : 'lastname',

    'DOB is required' : 'dob',
    'Validation for DOB failed. Date was not valid.' : 'dob',

    'Phone 1 is required' : 'phone',
    'Phone 1 is not a valid UK telephone number' : 'phone',

    'Postcode is not a valid UK postcode': 'postcode',
    'Postcode is required' : 'postcode',

    'Street 1 is required' : 'street1',

    //Product
    'property_value is required' : 'property_value',
    'mortgage_amount is required' : 'mortgage_amount',
};

export const getActiveFields = () => {
    let formElement = document.forms.namedItem("mainForm");
    let data = new FormData(formElement);
    let activeFields = [];

    for(var pair of data.entries()) {
        if(pair[1]) {
            activeFields.push(pair[0]);
        }
    }

    return activeFields;
}

//Process buyer
export const getTheBuyer = (data) => {

    let buyerData = {};

    data.records.forEach((el) => {
        if(el.status === 'Approved'){
            el.deliveries.forEach((el) => {
                if(el.rawResponse){
                    let convert = require('xml-js');
                    let result = convert.xml2json(el.rawResponse, {compact: true, spaces: 4});
                    result = JSON.parse(result)
                    buyerData = result.RESPONSE.BUYER;
                }
            })
        }
    });

    return buyerData

}


//Phone number checker
export const validatePhoneAXIOS = () =>{
    const candidURL = "https://candid.co.uk/api/phone_api.php?";
    let phoneNumber = document.getElementById('phone').value;
    let data = {
        num: phoneNumber,
        pwd: "wannaBuyLead",
    };
    let submitUrl = candidURL + 'num=' + data.num + '&pwd=' + data.pwd;
    return axios.get(submitUrl, {responseType: "text"}).then(response => {
        return response.data
    });
}



//Calcs age
export const getAge = (birthDate) => {
    let today = new Date();

    birthDate = new Date(birthDate.split('/')[2], birthDate.split('/')[1] - 1, birthDate.split('/')[0]);
    //birthDate = new Date(birthDate);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

//Calcs max amount that can be borrowed
export const calcMaxAmount = (propVal, birthDate) => {
    let currAge = 0;
    //currAge = this.getAge();
    currAge = getAge(birthDate);

    let rateTable = [
        {age: 55, max: 34.1},
        {age: 56, max: 35.2},
        {age: 56, max: 35.2},
        {age: 57, max: 36.3},
        {age: 58, max: 37.4},
        {age: 59, max: 38.5},
        {age: 60, max: 39.6},
        {age: 61, max: 40.7},
        {age: 62, max: 41.8},
        {age: 63, max: 42.9},
        {age: 64, max: 44},
        {age: 65, max: 45.1},
        {age: 66, max: 46.2},
        {age: 67, max: 47.3},
        {age: 68, max: 48.4},
        {age: 69, max: 49.5},
        {age: 70, max: 50},
        {age: 71, max: 50},
        {age: 72, max: 50},
        {age: 73, max: 50},
        {age: 74, max: 50},
        {age: 75, max: 50},
        {age: 76, max: 50},
        {age: 77, max: 50},
        {age: 78, max: 50},
        {age: 79, max: 50},
        {age: 80, max: 50},
        {age: 81, max: 50},
        {age: 82, max: 50},
        {age: 83, max: 50},
        {age: 84, max: 51},
        {age: 85, max: 52},
        {age: 86, max: 52},
        {age: 87, max: 52},
        {age: 88, max: 52},
        {age: 89, max: 52},
        {age: 90, max: 52},
    ];

    let lowerAge = rateTable[0].age;
    let upperAge = rateTable[rateTable.length - 1].age;

    if (currAge > upperAge) currAge = upperAge;
    if (currAge < lowerAge) currAge = lowerAge;
    let msg = propVal * rateTable[currAge - lowerAge].max / 100;
    msg = msg.toFixed(2);
    msg = msg.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return msg;

}
