import React, {Component} from 'react';
import { CSSTransition } from 'react-transition-group';
import PropertyValue from './fields/property_value';
import MortgageAmount from "./fields/mortgage_amount";
import Dob from "./fields/dob";
import { Tooltip } from 'reactstrap';

class Form1 extends Component{

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: {
                phone: false,
                email: false,
            }
        };
    }

    toggle(field) {

        this.setState({
            tooltipOpen: {
                [field]: !this.state.tooltipOpen[field],
            }
        });
    }

    nextStep = (step, e) => {

        e.preventDefault();
        this.props.updateFormStage(step);

    }

    render() {

        return(

            <React.Fragment>

                <div className="main-form__body">

                    <CSSTransition
                        classNames="form-stage"
                        in={this.props.formStage === 1 || this.props.errorCache.length > 0}
                        timeout={500}
                        mountOnEnter
                    >
                        <div>

                            <div className={
                                this.props.errorCache.length && !this.props.errorCache.includes('property_value')
                                    ? "form-group form-group--hide"
                                    : "form-group"}
                            >
                                <label>Estimated property value?</label>


                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fas fa-home"></i></span>
                                    </div>

                                    <PropertyValue
                                        error={this.props.errors.includes('property_value')}
                                        errorUpdater={this.props.errorUpdater}
                                    ></PropertyValue>
                                    <div className="invalid-feedback">
                                        Please choose an estimated property value.
                                    </div>
                                </div>
                            </div>

                            <div className={
                                this.props.errorCache.length && !this.props.errorCache.includes('mortgage_amount')
                                    ? "form-group form-group--hide"
                                    : "form-group"}
                            >
                                <label>Outstanding mortgage balance?</label>

                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fas fa-pound-sign"></i></span>
                                    </div>
                                    <MortgageAmount
                                        error={this.props.errors.includes('mortgage_amount')}
                                        errorUpdater={this.props.errorUpdater}
                                    ></MortgageAmount>
                                    <div className="invalid-feedback">
                                        Please provide a mortgage amount.
                                    </div>

                                </div>
                            </div>
                        </div>
                    </CSSTransition>


                    <CSSTransition
                        classNames="form-stage"
                        in={this.props.formStage === 2 || this.props.errorCache.length > 0}
                        timeout={300}
                        mountOnEnter
                    >

                        <div>

                            <div className={
                                this.props.errorCache.length && !this.props.errorCache.includes('dob')
                                    ? "form-group form-group--hide"
                                    : "form-group"}
                            >
                                <label>What is your date of birth?</label>
                                <Dob
                                    error={this.props.errors.includes('dob')}
                                    errorUpdater={this.props.errorUpdater}
                                ></Dob>
                                <div className={this.props.errors.includes('dob') ? "invalid-feedback invalid-feedback-force" : "invalid-feedback"}>
                                    Please provide a valid date of birth.
                                </div>
                            </div>

                        </div>


                    </CSSTransition>



                    <CSSTransition
                        classNames="form-stage"
                        in={this.props.formStage === 3 || this.props.errorCache.length > 0}
                        timeout={500}
                        mountOnEnter
                    >

                        <div>
                            <div className={
                                this.props.errorCache.length && !this.props.errorCache.includes('street1')
                                    ? "form-group form-group--hide"
                                    : "form-group"}
                            >
                                <label>First line of your address</label>

                                <div className="input-group">

                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fas fa-home"></i></span>
                                    </div>
                                    <input
                                        type="text"
                                        name="street1"
                                        id="street1"
                                        className={this.props.errors.includes('street1') ? "form-control is-invalid" : "form-control"}
                                        onChange={() => this.props.errorUpdater('street1')}
                                        maxLength="50"
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid address
                                    </div>

                                </div>

                            </div>

                            <div className={
                                this.props.errorCache.length && !this.props.errorCache.includes('postcode')
                                    ? "form-group form-group--hide"
                                    : "form-group"}
                            >
                                <label>Postcode</label>

                                <div className="input-group">

                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fas fa-map-marker-alt"></i></span>
                                    </div>
                                    <input
                                        type="text"
                                        name="postcode"
                                        id="postcode"
                                        className={this.props.errors.includes('postcode') ? "form-control is-invalid" : "form-control"}
                                        onChange={() => this.props.errorUpdater('postcode')}
                                        maxLength="50"
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid postcode
                                    </div>

                                </div>

                            </div>




                        </div>


                    </CSSTransition>


                    <CSSTransition
                        classNames="form-stage"
                        in={this.props.formStage === 4 || this.props.errorCache.length > 0}
                        timeout={500}
                        mountOnEnter
                    >

                        <div>


                            <div className={this.props.notSearched ? "alert alert-success" : "d-none"} role="alert">

                                {this.props.showCalc === "no" &&
                                <div>
                                    <strong>Good news!</strong> We have matched you with an adviser. Fill in the last few details to receive your personal calculation and quotes.
                                </div>

                                }
                                {this.props.showCalc === "yes" &&
                                <div>
                                    <strong>Good news!</strong> We have calculated how much you can release. Fill in the last few details to receive your quote and get matched with an equity release expert!
                                </div>
                                }




                            </div>


                            <div className={
                                this.props.errorCache.length && !this.props.errorCache.includes('firstname')
                                    ? "form-group form-group--hide"
                                    : "form-group"}
                            >
                                <label>First name</label>

                                <div className="input-group">

                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fas fa-user"></i></span>
                                    </div>

                                    <input
                                        type="text"
                                        name="firstname"
                                        id="firstname"
                                        className={this.props.errors.includes('firstname') ? "form-control is-invalid" : "form-control"}
                                        onChange={() => this.props.errorUpdater('firstname')}
                                        maxLength="50"
                                        required
                                    />

                                    <div className="invalid-feedback">
                                        Please provide a valid first name.
                                    </div>


                                </div>



                            </div>

                            <div className={
                                this.props.errorCache.length && !this.props.errorCache.includes('lastname')
                                    ? "form-group form-group--hide"
                                    : "form-group"}
                            >
                                <label>Last name</label>

                                <div className="input-group">

                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fas fa-user"></i></span>
                                    </div>
                                    <input
                                        type="text"
                                        name="lastname"
                                        id="lastname"
                                        className={this.props.errors.includes('lastname') ? "form-control is-invalid" : "form-control"}
                                        onChange={() => this.props.errorUpdater('lastname')}
                                        maxLength="50"
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid last name.
                                    </div>

                                </div>


                            </div>






                        </div>

                    </CSSTransition>


                    <CSSTransition
                        classNames="form-stage"
                        in={this.props.formStage === 5 || this.props.errorCache.length > 0}
                        timeout={500}
                        mountOnEnter
                    >

                        <div>

                            <div className={
                                this.props.errorCache.length && !this.props.errorCache.includes('email')
                                    ? "form-group form-group--hide"
                                    : "form-group"}
                            >
                                <label>Email address</label>


                                <span href="#" id="EmailDetails" className="text-muted">
                                    <i style={{marginLeft: '8px'}} className="far fa-question-circle"></i>
                                </span>
                                <Tooltip placement="right" isOpen={this.state.tooltipOpen.email} target="EmailDetails" toggle={() => this.toggle('email')}>
                                    We will only use your email address to contact you about your quote.
                                </Tooltip>

                                <div className="input-group">

                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fas fa-envelope"></i></span>
                                    </div>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        className={this.props.errors.includes('email') ? "form-control is-invalid" : "form-control"}
                                        onChange={() => this.props.errorUpdater('email')}
                                        maxLength="50"
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid email.
                                    </div>

                                </div>


                            </div>

                            <div className={
                                this.props.errorCache.length && !this.props.errorCache.includes('phone')
                                    ? "form-group form-group--hide"
                                    : "form-group"}
                            >
                                <label>Phone number</label>


                                <span href="#" id="PhoneDetails" className="text-muted">
                                    <i style={{marginLeft: '8px'}} className="far fa-question-circle"></i>
                                </span>
                                <Tooltip placement="right" isOpen={this.state.tooltipOpen.phone} target="PhoneDetails" toggle={() => this.toggle('phone')}>
                                    We will only use your phone number to contact you about your quote.
                                </Tooltip>



                                <div className="input-group">

                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fas fa-phone"></i></span>
                                    </div>
                                    <input
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        className={this.props.errors.includes('phone') ? "form-control is-invalid" : "form-control"}
                                        onChange={() => this.props.errorUpdater('phone')}
                                        maxLength="50"
                                        required
                                    />
                                    <div className="invalid-feedback">
                                        Please provide a valid phone number.
                                    </div>

                                </div>



                            </div>

                        </div>

                    </CSSTransition>


                </div>




                <div className="main-form__buttons">

                        <button className={this.props.formStage !== 5 ? 'btn btn-submit w-100' : 'd-none'} onClick={(e) => this.nextStep(1, e)}>Next</button>
                        <input type="submit" value="Get my quote" className={this.props.formStage === 5 ? 'btn btn-submit w-100' : 'd-none'}/>

                    <button className={this.props.errorCache.length || this.props.formStage === 1 ? 'd-none' : 'main-form__buttons__back'} onClick={(e) => this.nextStep(-1, e)}><i className="fas fa-chevron-left mr-2"></i>previous</button>

                    <div className={this.props.errorCache.length || this.props.formStage === 5 ? 'main-form__buttons__legal' : 'd-none'}>

                    </div>

                        <CSSTransition
                            classNames="form-stage"
                            in={this.props.formStage === 5 || this.props.errorCache.length > 0}
                            timeout={500}
                            mountOnEnter
                        >

                            <div className="main-form__buttons__legal">

                                <p>By requesting this service you agree to be contacted by email or telephone by
                                    an FCA Authorised Equity Release provider and agree to our <span className="btn-link" onClick={() => this.props.openPopup('terms')}>Terms of Use</span> and <span className="btn-link" onClick={() => this.props.openPopup('privacy')}>Privacy Policy</span>.</p>


                                <div>
                                    <img src="//www.shopperapproved.com/newseals/21691/black-mini-basic.gif"
                                         alt="Customer Reviews"
                                         />
                                    <img src="//ableplan.uk/site/uploads/2018/10/RapidSSL_SEAL-90x50.gif" alt="RapidSSL" />
                                </div>


                                <p className="main-form__buttons__legal__strong"><i className="fas fa-lock"></i> Your details are only collected to deal with your initial enquiry.  You will not be added to a marketing list.</p>


                            </div>

                        </CSSTransition>








                </div>




            </React.Fragment>

        );
    }

}

export default Form1;
