import React, {Component} from 'react';
import {Redirect} from "react-router-dom";

class Thankyou extends Component{

    componentDidMount () {
        //put user back at the top
        window.scrollTo(0, 0);

        if(!this.props.formProcessed) {
            return <Redirect to='/' />
        }
    }

    render() {

        return(
            <React.Fragment>

                <section className="thanks1">
                    <div className="thanks1__tick">
                        <div className="thanks1__tick__inner thanks1__tick__inner--animate">
                            <i className="fas fa-check"></i>
                        </div>
                    </div>
                    <div className="thanks1__message">
                        <h1 className="text-center">Thank you!</h1>

                        {this.props.calcMax &&
                        <div className="alert alert-primary" role="alert">
                            The max you can borrow is: <strong>£{this.props.calcAmount}</strong>
                        </div>
                        }

                        <p>Based on your answers we have selected one of our expert partners listed below to assist you.
                            They will contact you shortly to verify your requirements and provide your personal
                            calculation and answer any questions you may have. They have an excellent reputation and
                            have helped many people like yourself.</p>
                        <p>The initial call will only take a few minutes. If it is an inconvenient time the adviser can
                            organise to call you back at a better time.</p>
                        <p>There will be no charges for your initial consultation. Any charges thereafter will be made
                            clear to you and you are under no obligation to proceed.</p>
                        <p>Thank you again for using Able plan for your Equity Release enquiry.</p>
                    </div>
                </section>

                <section className={this.props.buyerData.BUYER_NAME ? "broker" : "d-none"}>
                    <div className="broker__title">Your broker's details</div>

                    <div className="broker__card">
                        {'BUYER_NAME' in this.props.buyerData &&
                        <h3>{this.props.buyerData.BUYER_NAME._text}</h3>
                        }
                        {'BUYER_DESCRIPTION' in this.props.buyerData &&
                        <p>{this.props.buyerData.BUYER_DESCRIPTION._text}</p>
                        }
                        <ul>

                            {'BUYER_PHONE' in this.props.buyerData &&
                            <li><i className="fas fa-phone"></i>
                                {this.props.buyerData.BUYER_PHONE._text}
                            </li>
                            }

                            {'BUYER_EMAIL' in this.props.buyerData &&
                            <li><i className="fas fa-envelope"></i>
                                {this.props.buyerData.BUYER_EMAIL._text}
                            </li>
                            }

                            {'BUYER_URL' in this.props.buyerData &&
                            <li><i className="fas fa-globe"></i>
                                {this.props.buyerData.BUYER_URL._text}
                            </li>
                            }
                        </ul>


                    </div>
                </section>

                {/*<section>*/}

                {/*    <div className="thanks1__message">*/}

                {/*        <br/><br/><br/><br/>*/}
                {/*    <h1>Made a will yet?</h1>*/}
                {/*    <p>Did you realise that if you haven’t made a will, you could leave your loved ones with nothing.*/}
                {/*        Most people think it’s complicated making a will – in fact nothing could be further from the*/}
                {/*        truth. You don’t need a solicitor and it doesn’t take long or involve lots of appointments.*/}
                {/*        We’ve partnered with the online will writing service Betterwill, to offer you an exclusive*/}
                {/*        discount on your will. It takes as little as 15 minutes and you can do it all online.</p>*/}
                {/*    <p>Remember; where there’s a will, you have a say.</p>*/}
                {/*    <a href="https://deals.betterwill.uk/" className="btn btn-submit" rel="noopener noreferrer" target="_blank">Get your will</a><br/><br/>*/}
                {/*    <p><strong>Use discount code: Betterwill35 to get 35% off</strong></p>*/}

                {/*    </div>*/}

                {/*</section>*/}





            </React.Fragment>
        )
    }

}

export default Thankyou;
